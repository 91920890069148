<template>
  <div class="dimensions grid two-columns">
    <div class="grid-item">
      <InputNumber
          title="Stützweite (m)"
          option="spanValue"
          :step="1"
          info-text="Max. 20 Meter" />
    </div>

    <div v-if="options.cantilever" class="grid-item">
      <InputNumber
          title="Kragarm (m)"
          option="cantileverValue"
          :step="1" />
    </div>
  </div>
</template>

<script>
import InputNumber from "@/components/InputNumber";
import {mapGetters} from "vuex";
export default {
  name: "Dimensions",
  components: {InputNumber},
  computed: {
    ...mapGetters(['options'])
  }
};
</script>

<style lang="scss">
.dimensions {
  grid-column-gap: 32px;

  @media (max-width: $width-tablet) {
    grid-template-columns: 1fr !important;
    grid-row-gap: 24px;
  }
}
</style>